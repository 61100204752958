@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
}

a {
  color: #0cc0df;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #0cc0df;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/* Back to top button */
.back-to-top {
  position: fixed;
  display: none;
  background: #0cc0df;
  color: #fff;
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 50%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  padding: 30px 0;
  height: 92px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  transition: all 1s;
  z-index: 997;
}

#header.header-scrolled {
  background: rgba(0, 0, 0, 0.9);
  padding: 20px 0;
  height: 72px;
  transition: all 0.5s;
}

#header #logo {
  float: left;
}

@media (min-width: 1024px) {
  #header #logo {
    padding-left: 60px;
  }
}

#header #logo h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
}

#header #logo h1 a,
#header #logo h1 a:hover {
  color: #fff;
  padding-left: 10px;
  border-left: 4px solid #0cc0df;
}

#header #logo img {
  padding: 0;
  margin: 0;
  max-height: 100px;
}

@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px;
  }
  #header #logo img {
    max-height: 60px;
  }
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/
#intro {
  display: table;
  width: 100%;
  height: 100vh;
  background: #000;
}

#intro .carousel-item {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#intro .carousel-item::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.custom-page {
  height: revert !important;
}
.custom-page .custom-content {
  height: 50vh !important;
}

@media (max-width: 550px) {
  .custom-page .custom-content {
    height: 70vh !important;
  }
}

#intro .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
/* @media (max-width: 350px) {
  .custom-page .carousel-container {
    align-items: end !important;
  }
} */

#intro .carousel-content {
  text-align: center;
}

#intro h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 60px;
  font-weight: 700;
}

@media (max-width: 768px) {
  #intro h2 {
    font-size: 28px;
  }
}

#intro p {
  width: 80%;
  margin: 0 auto 30px auto;
  color: #fff;
  font-size: 22px;
}

@media (min-width: 1024px) {
  #intro p {
    width: 60%;
  }
}
@media (max-width: 768px) {
  #intro p {
    font-size: revert;
  }
}

#intro .carousel-fade .carousel-inner .carousel-item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

#intro .carousel-fade .carousel-inner .carousel-item,
#intro .carousel-fade .carousel-inner .active.carousel-item-left,
#intro .carousel-fade .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

#intro .carousel-fade .carousel-inner .active,
#intro .carousel-fade .carousel-inner .carousel-item-next.carousel-item-left,
#intro .carousel-fade .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: 0.5s;
}

#intro .carousel-fade .carousel-inner .carousel-item-next,
#intro .carousel-fade .carousel-inner .carousel-item-prev,
#intro .carousel-fade .carousel-inner .active.carousel-item-left,
#intro .carousel-fade .carousel-inner .active.carousel-item-right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#intro .carousel-control-prev,
#intro .carousel-control-next {
  width: 10%;
}

@media (min-width: 1024px) {
  #intro .carousel-control-prev,
  #intro .carousel-control-next {
    width: 5%;
  }
}

#intro .carousel-control-next-icon,
#intro .carousel-control-prev-icon {
  background: none;
  font-size: 32px;
  line-height: 1;
}

#intro .carousel-indicators li {
  cursor: pointer;
}

#intro .btn-get-started {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 48px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: #0cc0df;
}

#intro .btn-get-started:hover {
  background: #fff;
  color: #0cc0df;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Nav Menu Essentials */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu > li {
  float: left;
}

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */
.sf-arrows .sf-with-ul {
  padding-right: 30px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */
#nav-menu-container {
  float: right;
  margin: 0;
}

@media (min-width: 1024px) {
  #nav-menu-container {
    padding-right: 60px;
  }
}

@media (max-width: 1115px) {
  #nav-menu-container {
    display: none;
  }
}

/* Nav Meu Styling */
.nav-menu a {
  padding: 0 30px 10px 8px;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  outline: none;
}

.nav-menu li:hover > a,
.nav-menu > .menu-active > a {
  color: #0cc0df;
}

.nav-menu li:hover > a,
.nav-menu > a.menu-active {
  color: #0cc0df;
}
.nav-menu > li {
  margin-left: 10px;
}

.nav-menu ul {
  margin: 4px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 13px;
  text-transform: none;
}

.nav-menu ul li:hover > a {
  color: #0cc0df;
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */
#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 20px 20px 0 0;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer;
  z-index: 999;
}

#mobile-nav-toggle i {
  color: #fff;
}

@media (max-width: 1115px) {
  #mobile-nav-toggle {
    display: inline;
  }
}

/* Mobile Nav Styling */
#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #0cc0df;
}

#mobile-nav ul .menu-has-children li a {
  text-transform: none;
}

#mobile-nav ul .menu-item-active {
  color: #0cc0df;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

/* Mobile Nav body classes */
body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
/* Sections Header
--------------------------------*/
.section-header h3 {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

.section-header h3::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-header h3::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #0cc0df;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-header h2 {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

/* .section-header h3::before {
  content: '';
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-header h3::after {
  content: '';
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #0CC0DF;
  bottom: 0;
  left: calc(50% - 20px);
} */

.section-header p {
  text-align: center;
  padding-bottom: 30px;
  color: #333;
  font-size: 18px;
}

/* Section with background
--------------------------------*/
.section-bg {
  background: #f7f7f7;
}

/* Featured Services Section
--------------------------------*/
#featured-services {
  background: #000;
}

#featured-services .box {
  padding: 30px 20px;
}

#featured-services .box-bg {
  background-image: linear-gradient(
    0deg,
    #000000 0%,
    #242323 50%,
    #000000 100%
  );
}

#featured-services i {
  color: #0cc0df;
  font-size: 48px;
  display: inline-block;
  line-height: 1;
}

#featured-services h4 {
  font-weight: 600;
  margin: 15px 0;
  font-size: 22px;
}

#featured-services h4 a {
  color: #fff;
}

#featured-services h4 a:hover {
  color: #0cc0df;
}

#featured-services p {
  font-size: 17px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
}

/* About Us Section
--------------------------------*/
#about {
  background: url("/public/img/about-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  padding: 80px 0 40px 0;
  position: relative;
}

#about::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.92);
  z-index: 9;
}

#about .container {
  position: relative;
  z-index: 10;
}

#about .about-col {
  background: #fff;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

#about .about-col .img {
  position: relative;
}

#about .about-col .img img {
  border-radius: 4px 4px 0 0;
}

#about .about-col .icon {
  width: 64px;
  height: 64px;
  padding-top: 8px;
  text-align: center;
  position: absolute;
  background-color: #0cc0df;
  border-radius: 50%;
  text-align: center;
  border: 4px solid #fff;
  left: calc(50% - 32px);
  bottom: -30px;
  transition: 0.3s;
}

#about .about-col i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
  transition: 0.3s;
}

#about .about-col:hover .icon {
  background-color: #fff;
}

#about .about-col:hover i {
  color: #0cc0df;
}

#about .about-col h2 {
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  margin: 40px 0 12px 0;
}

#about .about-col h2 a {
  color: #000;
}

#about .about-col h2 a:hover {
  color: #0cc0df;
}

#about .about-col p {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  margin-bottom: 0;
  padding: 0 20px 20px 20px;
}

/* Services Section
--------------------------------*/
#services {
  background: #fff;
  background-size: cover;
  padding: 80px 0 40px 0;
}

#services .box {
  margin-bottom: 30px;
}

#services .icon {
  float: left;
}

#services .icon i {
  color: #0cc0df;
  font-size: 36px;
  line-height: 1;
  transition: 0.5s;
}

#services .title {
  margin-left: 60px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

#services .title a,
#services .title p {
  color: #111;
  margin-bottom: 0;
  cursor: pointer;
}

#services .box:hover .title a,
#services .box:hover .title p {
  color: #0cc0df;
}

#services .description {
  font-size: 16px;
  margin-left: 60px;
  line-height: 24px;
  margin-bottom: 0;
}

/* Call To Action Section
--------------------------------*/
#call-to-action {
  background: linear-gradient(rgba(0, 142, 99, 0.1), rgba(0, 0, 0, 0.1)),
    url(/public/img/call-to-action-bg.jpg) fixed center center;
  background-size: cover;
  padding: 60px 0;
}

#call-to-action h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

#call-to-action p {
  color: #fff;
  font-size: 20px;
}

#call-to-action .cta-btn {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

#call-to-action .cta-btn:hover {
  background: #0cc0df;
  border: 2px solid #0cc0df;
}

/* Call To Action Section
--------------------------------*/
#skills {
  padding: 60px 0;
}

#skills .progress {
  height: 35px;
  margin-bottom: 10px;
}

#skills .progress .skill {
  font-family: "Open Sans", sans-serif;
  line-height: 35px;
  padding: 0;
  margin: 0 0 0 20px;
  text-transform: uppercase;
}

#skills .progress .skill .val {
  float: right;
  font-style: normal;
  margin: 0 20px 0 0;
}

#skills .progress-bar {
  width: 1px;
  text-align: left;
  transition: 0.9s;
}

/* Facts Section
--------------------------------*/
#facts {
  background: url("/public/img/facts-bg.jpg") center top no-repeat fixed;
  background-size: cover;
  padding: 60px 0 0 0;
  position: relative;
}

#facts::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.88);
  z-index: 9;
}

#facts .container {
  position: relative;
  z-index: 10;
}

#facts .counters span {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 48px;
  display: block;
  color: #0cc0df;
}

#facts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #111;
}

#facts .facts-img {
  text-align: center;
  padding-top: 30px;
}

/* Portfolio Section
--------------------------------*/
#portfolio {
  padding: 80px 0 40px 0;
}

#portfolio #portfolio-flters {
  padding: 0;
  margin: 5px 0 35px 0;
  list-style: none;
  text-align: center;
}

#portfolio #portfolio-flters li {
  cursor: pointer;
  margin: 15px 15px 15px 0;
  display: inline-block;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  color: #666666;
  border-radius: 4px;
  text-transform: uppercase;
  background: #fff;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

#portfolio #portfolio-flters li:hover,
#portfolio #portfolio-flters li.filter-active {
  background: #0cc0df;
  color: #fff;
}

#portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

#portfolio .portfolio-wrap {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
}

#portfolio .portfolio-wrap:hover {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
}

#portfolio .portfolio-item {
  position: relative;
  height: 360px;
  overflow: hidden;
}

#portfolio .portfolio-item figure {
  background: #000;
  overflow: hidden;
  height: 240px;
  position: relative;
  border-radius: 4px 4px 0 0;
  margin: 0;
}

#portfolio .portfolio-item figure:hover img {
  opacity: 0.4;
  transition: 0.3s;
}

#portfolio .portfolio-item figure .link-preview,
#portfolio .portfolio-item figure .link-details {
  position: absolute;
  display: inline-block;
  visibility: hidden;
  line-height: 1;
  text-align: center;
  width: 36px;
  height: 36px;
  background: #fff;
  border-radius: 50%;
  transition: 0.2s;
}

#portfolio .portfolio-item figure .link-preview i,
#portfolio .portfolio-item figure .link-details i {
  padding-top: 6px;
  font-size: 22px;
  color: #333;
}

#portfolio .portfolio-item figure .link-preview:hover,
#portfolio .portfolio-item figure .link-details:hover {
  background: #0cc0df;
}

#portfolio .portfolio-item figure .link-preview:hover i,
#portfolio .portfolio-item figure .link-details:hover i {
  color: #fff;
}

#portfolio .portfolio-item figure .link-preview {
  left: calc(50% - 38px);
  top: calc(50% - 18px);
}

#portfolio .portfolio-item figure .link-details {
  right: calc(50% - 38px);
  top: calc(50% - 18px);
}

#portfolio .portfolio-item figure:hover .link-preview {
  visibility: visible;
  left: calc(50% - 44px);
}

#portfolio .portfolio-item figure:hover .link-details {
  visibility: visible;
  right: calc(50% - 44px);
}

#portfolio .portfolio-item .portfolio-info {
  background: #fff;
  text-align: center;
  padding: 30px;
  height: 90px;
  border-radius: 0 0 3px 3px;
}

#portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  line-height: 1px;
  font-weight: 700;
  margin-bottom: 18px;
  padding-bottom: 0;
}

#portfolio .portfolio-item .portfolio-info h4 a {
  color: #333;
}

#portfolio .portfolio-item .portfolio-info h4 a:hover {
  color: #0cc0df;
}

#portfolio .portfolio-item .portfolio-info p {
  padding: 0;
  margin: 0;
  color: #b8b8b8;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

/* Clients Section
--------------------------------*/
#clients {
  padding: 60px 0;
}

#clients img {
  max-width: 100%;
  opacity: 0.5;
  transition: 0.3s;
  padding: 15px 0;
}

#clients img:hover {
  opacity: 1;
}

#clients .owl-nav,
#clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#clients .owl-dot.active {
  background-color: #0cc0df;
}

/* Testimonials Section
--------------------------------*/
#testimonials {
  padding: 70px 0;
}

.card {
  /* width: 300px; */
  height: 300px;
  transform-style: preserve-3d;
  perspective: 500px;
  border: none;
  background-color: inherit;
  color: #000;
  margin-bottom: 15px;
}

.card .face {
  position: absolute;
  color: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.06);
  transform-style: preserve-3d;
  transition: 0.5s;
  backface-visibility: hidden;
  border: 1px solid #ddd;
  color: #000;
  z-index: -1;
  /* border-right: 1px solid #999;
border-bottom: 1px solid #999; */
}

.card .face.front-face,
.card .face.back-face {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card .face.front-face .profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.card .face.front-face .name {
  font-weight: 600;
}

.card .face.front-face .designation {
  font-size: 0.8rem;
  color: #ddd;
  letter-spacing: 0.8px;
  color: #000;
}

.card:hover .face.front-face {
  transform: rotateY(180deg);
  border: 1px solid #ddd;
}

.card .face.back-face {
  position: absolute;
  background: rgba(255, 255, 255, 0.06);
  transform: rotateY(180deg);
  padding: 20px 30px;
  text-align: center;
  user-select: none;
}

.card .face.back-face .fa-quote-left {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 1.2rem;
}

.card .face.back-face .fa-quote-right {
  position: absolute;
  bottom: 35px;
  right: 25px;
  font-size: 1.2rem;
}

.card:hover .face.back-face {
  transform: rotateY(360deg);
  border: 1px solid #ddd;
}

@media (max-width: 991.5px) {
  .col-lg-4 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .card {
    overflow: hidden;
    height: 300px;
    transform-style: preserve-3d;
    border: none;
    background-color: inherit;
    color: #000;
    margin-bottom: 15px;
    perspective: none;
  }
}

/* testimonials */
.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ececec;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
}
#customers-testimonials .shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  /* font-weight: 300; */
}
.testimonial-name {
  margin: -17px auto 0;
  display: table;
  width: auto;
  background: #0cc0df;
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0 9px 18px rgba(0, 0, 0, 0.12), 0 5px 7px rgba(0, 0, 0, 0.05);
}
#customers-testimonials .item {
  text-align: center;
  padding: 50px;
  margin-bottom: 80px;
  opacity: 0.2;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#customers-testimonials .owl-item.active.center .item {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  /* max-width: 90px; */
  margin: 0 auto 17px;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot.active span,
#customers-testimonials.owl-carousel .owl-dots .owl-dot:hover span {
  background: #0cc0df;
  transform: translate3d(0px, -50%, 0px) scale(0.7);
}
#customers-testimonials.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}
#customers-testimonials.owl-carousel .owl-dots .owl-dot span {
  background: #0cc0df;
  display: inline-block;
  height: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
  width: 20px;
}

#testimonials .section-header {
  margin-bottom: 40px;
}

#testimonials .testimonial-item {
  text-align: center;
}

#testimonials .testimonial-item .testimonial-img {
  width: 120px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

#testimonials .testimonial-item h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

#testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 15px 0;
}

#testimonials .testimonial-item .quote-sign-left {
  margin-top: -15px;
  padding-right: 10px;
  display: inline-block;
  width: 37px;
}

#testimonials .testimonial-item .quote-sign-right {
  margin-bottom: -15px;
  padding-left: 10px;
  display: inline-block;
  max-width: 100%;
  width: 37px;
}

#testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  font-size: 20px;
}

@media (min-width: 992px) {
  #testimonials .testimonial-item p {
    width: 80%;
  }
}

#testimonials .owl-nav,
#testimonials .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#testimonials .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#testimonials .owl-dot.active {
  background-color: #0cc0df;
}

/* Team Section
--------------------------------*/
#team {
  background: #fff;
  padding: 80px 0 40px 0;
}

#team .member {
  text-align: center;
  margin-bottom: 20px;
  background: #000;
  position: relative;
  border-radius: 50%;
}

#team .member .member-info {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.2s;
  border-radius: 50%;
}

#team .member .member-info-content {
  margin-top: -50px;
  transition: margin 0.2s;
}

#team .member:hover .member-info {
  background: rgba(0, 0, 0, 0.7);
  visibility: visible;
  transition: 0.4s;
}

#team .member:hover .member-info-content {
  margin-top: 0;
  transition: margin 0.4s;
}

#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
  color: #fff;
}

#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #fff;
}

#team .member .social {
  margin-top: 15px;
}

#team .member .social a {
  transition: none;
  color: #fff;
}

#team .member .social a:hover {
  color: #0cc0df;
}

#team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Contact Section
--------------------------------*/
#contact {
  padding: 80px 0 40px 0;
  background: #fff;
}

#contact .contact-info {
  margin-bottom: 20px;
  text-align: center;
}

#contact .contact-info i {
  font-size: 48px;
  display: inline-block;
  margin-bottom: 10px;
  color: #0cc0df;
}

#contact .contact-info address,
#contact .contact-info p {
  margin-bottom: 0;
  color: #000;
}

#contact .contact-info h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}

#contact .contact-info a {
  color: #000;
}

#contact .contact-info a:hover {
  color: #0cc0df;
}

#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    padding: 20px 0;
  }
}

@media (min-width: 768px) {
  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
}

#contact .form #sendmessage {
  color: #0cc0df;
  border: 1px solid #0cc0df;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

#contact .form button[type="submit"] {
  background: #0cc0df;
  font-weight: 600;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 0 9px 18px rgb(0 0 0 / 12%), 0 5px 7px rgb(0 0 0 / 5%);
  border-radius: 12px;
}

#contact .form button[type="submit"]:hover {
  background: #0cc0df;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #000;
  padding: 0 0 30px 0;
  color: #eee;
  font-size: 14px;
}

#footer .footer-top {
  background: #111;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
  text-align: center;
}
#footer .footer-top .footer-info img {
  max-width: 150px;
}
@media (max-width: 768px) {
  .footer-info img {
    max-width: 100px;
  }
}
#footer .footer-top .footer-info h3 {
  font-size: 34px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 10px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  border-left: 4px solid #0cc0df;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  color: #eee;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #eee;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #0cc0df;
  color: #fff;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top h4::before,
#footer .footer-top h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}

#footer .footer-top h4::before {
  right: 0;
  background: #555;
}

#footer .footer-top h4::after {
  background: #0cc0df;
  width: 60px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 8px;
  color: #ddd;
}

#footer .footer-top .footer-links ul li {
  /* border-bottom: 1px solid #333; */
  padding: 10px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #eee;
}

#footer .footer-top .footer-links ul a:hover {
  color: #0cc0df;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .footer-top .footer-newsletter {
  margin-bottom: 30px;
}

#footer .footer-top .footer-newsletter input[type="email"] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
}

#footer .footer-top .footer-newsletter input[type="submit"] {
  background: #0cc0df;
  border: 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}

#footer .footer-top .footer-newsletter input[type="submit"]:hover {
  background: #0cc0df;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #ddd;
}
.field-error {
  color: red;
}

/* blogs */

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-xs-12 {
  width: 100%;
}

.blog-grids {
  overflow: hidden;
  margin: 0 -15px;
}

.grid {
  background-color: #fff;
  float: left;
  padding: 15px;
  margin: 20px 15px 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.grid p {
  padding: revert !important;
  padding-bottom: 20px !important;
}

.entry-media img {
  border-radius: 10px;
  width: 100%;
  max-height: 188px;
}

.entry-body {
  padding: 27px 10px;
}

.entry-body .cat {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #6220d9;
  text-transform: uppercase;
}

.entry-body h3 {
  font-size: 21px;
  font-weight: 600;
  line-height: 1.3em;
  margin: 3px 0 0.73em;
}

.entry-body h3 a {
  color: #41516a;
}

.entry-body h3 a:hover {
  color: #6220d9;
  text-decoration: none;
}
.entry-body div a:hover {
  color: #6220d9;
  text-decoration: none;
}

.entry-body p {
  margin-bottom: 2em;
  color: #90949a;
  line-height: 1.8em;
}

.read-more-date {
  position: relative;
}

.read-more-date a {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-size: 1.06667rem;
  font-weight: 600;
  color: #41516a;
  text-transform: uppercase;
}

.read-more-date .date {
  position: absolute;
  right: 0;
  color: #90949a;
}

/* Accrediations */

#accreditations {
  padding: 70px 0;
}
.partner-text {
  text-align: left !important;
  margin-top: 5%;
}

@media (max-width: 991.5px) {
  .aws-sales {
    float: revert;
    width: 100%;
  }
}
